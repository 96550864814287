import React from "react";
import styled from "styled-components";

import ic_bell from "assets/icon/ic_bell.svg";
import color from "assets/color";
import { env } from "common/_helpers/config";

interface AlarmCardProps {
  onClick?: () => void;
}

function AlarmCard(props: AlarmCardProps) {
  const handleButtonClick = () => {
    const deepLinkBaseUrl = env.OILNOW_DEEPLINK || "";

    if (props.onClick) {
      props.onClick();
    }

    window.location.href = `${deepLinkBaseUrl}://push-setting?information=true&marketing=true`;
  };

  return (
    <Container>
      <Box>
        <BellIcon src={ic_bell} />
        <Title>{"새로운 소식을 받아보세요"}</Title>
        <Description>{"내 차를 아끼는 정보를 알려드릴게요!"}</Description>
        <Button onClick={handleButtonClick}>{"알림 설정하기"}</Button>
      </Box>
    </Container>
  );
}

const Container = styled.div`
  padding: 4rem 4.3rem 2rem 4.3rem;
  background: ${color.gray100};
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2.8rem 2rem;
  background: ${color.white};
  border-radius: 1.6rem;
`;

const BellIcon = styled.img`
  width: 7.2rem;
  height: 7.2rem;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: ${color.gray800};
  margin-top: 1.6rem;
`;

const Description = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${color.gray600};
  margin-top: 0.4rem;
`;

const Button = styled.button`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: ${color.blue500};
  padding: 1rem 1.6rem;
  background: ${color.blue50};
  margin-top: 2rem;
  border-radius: 1rem;
`;

export default AlarmCard;
