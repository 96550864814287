import axios from "axios";
import { useEffect, useState } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { env } from "common/_helpers/config";
import Notice from "domain/entity/notice";
import parseResponse from "common/_utils/parse_response";

const fetcher = async (id: number) => {
  try {
    const res = parseResponse<Notice, undefined>(
      await axios.get(`${env.API_URL}/notices/${id}`),
    );

    return res;
  } catch {
    return undefined;
  }
};

const useNotice = (id: number) => {
  const [notice, setNotice] = useState<Notice>();

  const { data } = useQuery({
    queryKey: ["notices", id],
    queryFn: () => fetcher(id),
  });

  useEffect(() => {
    if (data) {
      setNotice(data);
    }
  }, [data]);

  return {
    notice,
  };
};

export default useNotice;
