import color from "assets/color";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: "Spoqa Han Sans", "Sans-serif";
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 62.5%;
    line-height: 150%;
    letter-spacing: -0.03em;
    word-break: keep-all;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: transparent;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
    font-weight: 400;
    text-size-adjust: none;
    -webkit-tap-highlight-color: transparent;
  }

  html,
  body {
    position: fixed;
    overflow: hidden;
}

  #root {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    display: flex;
    justify-content: center;
  }

  button {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: 0;
    border: none;
    font-size: 1.6rem;

    :focus {
      outline: 0;
      box-shadow: none !important;
    }
    :hover {
      cursor: pointer !important;
    }

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  input {
    :focus {
      outline: 0;
      box-shadow: none !important;
    }
    outline: none;
    border-radius: 0;
    box-shadow: none;
    appearance: none;
    font-size: 1.6rem;
    letter-spacing: -0.03em;
  }

  textarea {
    outline: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: white;
    font-size: 1.6rem;
    line-height: 150%;
    letter-spacing: -0.03em;
  }

  p {
    margin: 0;
    font-size: 1.6rem;
    -webkit-tap-highlight-color: transparent;
  }

  h1 {
    font-weight: 700;
    color: ${color.gray800}
  }

  h3 {
    font-size: 1.6rem;
    color: ${color.gray800}
  }


  span {
    font-size: 1.6rem;
    -webkit-tap-highlight-color: transparent;
  }

  div {
    font-size: 1.6rem;
    -webkit-tap-highlight-color: transparent;
  }

  a {
    font-size: 1.6rem;
    -webkit-tap-highlight-color: transparent;
  }

  strong {
    font-size: 1.6rem;
  }

  u {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.6rem;
  }
`;
