import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { env } from "common/_helpers/config";
import Notice, { NoticeType } from "domain/entity/notice";
import parseResponse from "common/_utils/parse_response";
import PlatformType from "domain/enum/platform_type";

const fetcher = async (platform: PlatformType) => {
  try {
    const res = parseResponse<{ notices: Notice[] }, undefined>(
      await axios.get(`${env.API_URL}/notices`, {
        params: {
          offset: 0,
          limit: 4,
          platform,
          type: NoticeType.CONTENT,
        },
      }),
    );

    return res?.notices;
  } catch {
    return undefined;
  }
};

const useRecommendedNotices = (props: {
  currentNoticeId: number;
  platform: PlatformType;
}) => {
  const { currentNoticeId, platform } = props;
  const [recommendedNotices, setRecommendedNotices] = useState<Notice[]>([]);

  const { data: res } = useQuery({
    queryKey: [`recommended_notices`, currentNoticeId],
    queryFn: () => fetcher(platform),
  });

  useEffect(() => {
    if (res) {
      const notices: Notice[] = [];

      res.forEach((notice) => {
        if (notices.length < 3 && notice.id !== currentNoticeId) {
          notices.push(notice);
        }
      });

      setRecommendedNotices(notices);
    }
  }, [res]);

  return {
    recommendedNotices,
  };
};

export default useRecommendedNotices;
