import { isIOS } from "react-device-detect";

const copyClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (e) {
    const el = document.createElement("textarea");

    el.value = text;
    el.setAttribute("readonly", "");
    document.body.appendChild(el);
    el.select();

    if (isIOS) {
      const range = document.createRange();
      range.selectNodeContents(el);
      const selection = window.getSelection();
      selection!.removeAllRanges();
      selection!.addRange(range);
      el.setSelectionRange(0, 999999);
    } else {
      el.select();
    }

    const result = document.execCommand("copy");
    document.body.removeChild(el);
    return result ? true : false;
  }
};

export default copyClipboard;
