import { useAtom, useSetAtom } from "jotai";
import { useEffect } from "react";

import { isToastOpenAtom, toastMessageAtom } from "../_atom";

export const useToastMessage = () => {
  const [isToastOpen, setToastOpen] = useAtom(isToastOpenAtom);
  const setToastMessage = useSetAtom(toastMessageAtom);

  useEffect(() => {
    if (!isToastOpen) return;

    const timer = setTimeout(() => {
      setToastOpen(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [isToastOpen, setToastOpen]);

  const showToastMessage = (message: string) => {
    setToastMessage(message);
    setToastOpen(true);
  };

  return showToastMessage;
};
