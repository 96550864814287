import axios from "axios";
import { useEffect, useState } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { env } from "common/_helpers/config";
import Notice from "domain/entity/notice";
import parseResponse from "common/_utils/parse_response";
import PlatformType from "domain/enum/platform_type";

const LIMIT = 20;

export interface UseNoticesProps {
  platform: PlatformType;
}

const fetcher = async (props: UseNoticesProps & { offset: number }) => {
  const { platform, offset } = props;

  try {
    const res = parseResponse<{ notices: Notice[] }, undefined>(
      await axios.get(`${env.API_URL}/notices`, {
        params: {
          platform,
          offset,
          limit: LIMIT,
        },
      }),
    );

    return res?.notices;
  } catch {
    return undefined;
  }
};

const useNotices = (props: UseNoticesProps) => {
  const [notices, setNotices] = useState<Notice[]>([]);

  const { data, fetchNextPage: fetchNextNotices } = useInfiniteQuery({
    queryKey: ["notices", props.platform],
    queryFn: ({ pageParam }) => fetcher({ ...props, offset: pageParam }),
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage || lastPage.length < LIMIT) {
        return undefined;
      }

      return allPages.length * LIMIT;
    },
    initialPageParam: 0,
  });

  useEffect(() => {
    if (data) {
      setNotices(data?.pages.flatMap((page) => page ?? []));
    }
  }, [data]);

  return {
    notices,
    fetchNextNotices,
  };
};

export default useNotices;
