import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import AccountManager from "common/_helpers/account_manager";
import hackleManager from "common/_helpers/hackle_manager";
import AppCommunicator from "common/_helpers/app_communicator";
import { env } from "common/_helpers/config";

import image_01 from "./_assets/image_01.jpg";
import image_02 from "./_assets/image_02.jpg";
import image_03 from "./_assets/image_03.jpg";
import image_04 from "./_assets/image_04.png";
import image_05 from "./_assets/image_05.png";
import image_06 from "./_assets/image_06.png";
import image_07 from "./_assets/image_07.png";
import image_08 from "./_assets/image_08.jpg";
import image_09 from "./_assets/image_09.jpg";
import button_01 from "./_assets/button_01.jpg";
import button_02 from "./_assets/button_02.jpg";
import button_03 from "./_assets/button_03.jpg";
import OkDialog from "./_components/ok_dialog";

const HyundaiOilBankPage = () => {
  const [isOkDialogOpen, setIsOkDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const navigate = useNavigate();

  const moveToAttendPage = async () => {
    const token = await AccountManager.getAccessToken();
    hackleManager.track("cta_230501_hyundaioilbank_promotion");
    AppCommunicator.logGaEvent("cta_230501_hyundaioilbank_promotion");

    try {
      await axios.get(
        `${env.ACCOUNT_API_URL}/partnership/event/oilbank-01/attendee`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setDialogText("이미 이벤트에 참여하셨어요.");
      setIsOkDialogOpen(true);
    } catch (e: any) {
      if (e?.response?.data?.code === 404001) {
        navigate("/hyundaioilbank/attend");
      } else {
        setDialogText("앱 로그인 후 이용해주세요!");
        setIsOkDialogOpen(true);
      }
    }
  };

  const handleClickDialogOk = () => {
    setIsOkDialogOpen(false);
  };

  const handleClickShare = () => {
    window.location.href = "oilnow://kakao_recommend?templateId=33511";
  };

  const handleClickSearchStation = () => {
    AppCommunicator.logGaEvent("cta_230501_hyundaioilbank_outlanding");
    hackleManager.track("cta_230501_hyundaioilbank_outlanding");

    window.location.href =
      "http://www.oilbankcard.com//m2012/front/boardList.do?bcode=EULGAS_OIL&oilChk=UK&outbound=true";
  };

  return (
    <>
      <OkDialog
        text={dialogText}
        isOpen={isOkDialogOpen}
        onClickOk={handleClickDialogOk}
      />
      <Container>
        <Image src={image_01} alt={""} />
        <Image src={image_02} alt={""} />
        <Image style={{ marginTop: "1.6rem" }} src={image_03} alt={""} />
        <AttendButton
          src={button_01}
          alt={"주유 쿠폰 받으러 가기"}
          onClick={moveToAttendPage}
        />
        <ShareButton
          src={button_02}
          alt={"카카오톡 공유하기"}
          onClick={handleClickShare}
        />
        <MainImageBox>
          <Image src={image_04} alt={""} />
          <Image src={image_05} alt={""} />
          <Image src={image_06} alt={""} />
          <Image src={image_07} alt={""} />
        </MainImageBox>
        <FindStationButton
          src={button_03}
          alt={"HD현대오일뱅크 주유소 찾기"}
          onClick={handleClickSearchStation}
        />
        <Image src={image_08} alt={""} />
        <BottomImage src={image_09} alt={""} />
      </Container>
    </>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
`;

const Image = styled.img`
  display: block;
  width: 100%;
`;

const MainImageBox = styled.div`
  padding: 0 2rem;
  line-height: 0;
`;

const BottomImage = styled.img`
  width: 100%;
  margin-top: 1.6rem;
  margin-bottom: 5rem;
`;

const AttendButton = styled.img`
  width: 24rem;
  margin-top: 1.7rem;
`;

const ShareButton = styled.img`
  width: 24rem;
  margin-top: 3.7rem;
  margin-bottom: 6rem;
`;

const FindStationButton = styled.img`
  width: 24rem;
  margin-top: 4.5rem;
  margin-bottom: 2.7rem;
`;

export default HyundaiOilBankPage;
