import { useEffect, RefObject } from "react";
import { createBrowserHistory } from "history";

import useThrottle from "./use_throttle";

const history = createBrowserHistory();

interface UseScrollMove {
  dom?: RefObject<any>;
  timeout?: number;
}

const useScrollMove = ({ dom, timeout = 0 }: UseScrollMove) => {
  const scrollMove = () => {
    if (dom && dom.current && dom.current.scrollTop !== null) {
      if (history.action === "PUSH") {
        dom.current.scrollTo(0, 0);
      }
      if (
        history.action === "POP" &&
        window.history.state &&
        window.history.state.scroll
      ) {
        setTimeout(() => {
          dom.current.scrollTop = window.history.state.scroll;
        }, timeout);
      }
    } else {
      if (history.action === "PUSH") {
        window.scrollTo(0, 0);
      }
      if (
        history.action === "POP" &&
        window.history.state &&
        window.history.state.scroll
      ) {
        setTimeout(() => {
          window.scrollTo(0, window.history.state.scroll);
        }, timeout);
      }
    }
  };

  const scrollSave = () => {
    const { location } = window;
    const { state: prevState = {} } = window.history;
    if (dom && dom.current && dom.current.scrollTop) {
      window.history.replaceState(
        { ...prevState, scroll: dom.current.scrollTop.toString() },
        "",
        location.pathname,
      );
    } else {
      window.history.replaceState(
        { ...prevState, scroll: window.scrollY.toString() },
        "",
        location.pathname,
      );
    }
  };

  const throttledScrollSave = useThrottle(scrollSave, 300);

  useEffect(() => {
    if (dom && dom.current) {
      dom.current.addEventListener("scroll", throttledScrollSave);
    }

    return () => {
      if (dom && dom.current) {
        dom.current.removeEventListener("scroll", throttledScrollSave);
      }
    };
  });

  return { scrollMove };
};

export default useScrollMove;
