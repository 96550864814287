const zIndex = {
  layer0: -1,
  layer1: 1,
  layer2: 3,
  layer3: 5,
  layer4: 10,
  layer5: 15,
};

export default zIndex;
