import React from "react";
import styled from "styled-components";

import ic_loading from "assets/icon/ic_loading.png";

const LoadingIndicator = () => {
  return <Container alt="로딩중" src={ic_loading} />;
};

const Container = styled.img`
  width: 2.4rem;
  height: 2.4rem;

  @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  animation: rotating 2s linear infinite;
`;

export default LoadingIndicator;
