class AccountManager {
  private uid = "";
  private accessToken = "";
  private email = "";

  public getUid() {
    if (this.uid && this.uid !== "") {
      return this.uid;
    }

    const win = window as any;

    if (win.oilnow) {
      this.uid = win.oilnow.getUID();
    } else {
      const uid = sessionStorage.getItem("uid");
      this.uid = uid ? uid : "";
    }

    return this.uid;
  }

  public async getAccessToken() {
    if (this.accessToken && this.accessToken !== "") {
      return this.accessToken;
    }

    const win = window as any;

    if (win.oilnow) {
      this.accessToken = await win.oilnow.getAccessToken();
    } else {
      const accessToken = sessionStorage.getItem("accessToken");
      this.accessToken = accessToken ? accessToken : "";
    }

    return this.accessToken;
  }

  public async getEmail() {
    if (this.email && this.email !== "") {
      return this.email;
    }

    const win = window as any;

    if (win.oilnow) {
      this.email = await win.oilnow.getEmail();
    } else {
      const email = sessionStorage.getItem("email");
      this.email = email ? email : "";
    }

    return this.email;
  }
}

export default new AccountManager();
