import React, { ReactNode, useState } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

export default function ReactQuerySetUp(props: { children: ReactNode }) {
  const [client] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: 3,
        },
      },
    }),
  );

  return (
    <QueryClientProvider client={client}>{props.children}</QueryClientProvider>
  );
}
