import zipObject from "lodash/zipObject";
import map from "lodash/map";
import keys from "lodash/keys";
import values from "lodash/values";
import toString from "lodash/toString";

let _env = process.env;
_env.debug = toString(_env.NODE_ENV !== "production");
const prefix = "REACT_APP_";
const stripReact = (key: String) => key.replace(prefix, "");

export const env = zipObject(map(keys(_env), stripReact), values(_env));
