import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

import { env } from "common/_helpers/config";
import hackleManager from "common/_helpers/hackle_manager";
import AccountManager from "common/_helpers/account_manager";
import DialogPanel from "./DialogPanel";

import NoticeListPage from "pages/Notice/notice_list_page";
import NoticeDetailPage from "pages/Notice/notice_detail_page";
import EVPostPage from "pages/Partnership/ev_post/page";
import HyundaiOilBankPage from "pages/Partnership/hyundai_oil_bank/hyundai_oil_bank_page";
import AttendPage from "pages/Partnership/hyundai_oil_bank/attend_page";
import TrackingPage from "pages/Notice/tracking_page";

import { GlobalStyle } from "./GlobalStyle";
import NavigationBar from "common/_components/navigation_bar";
import { ToastMessage } from "common/_components/toast";

function App() {
  useEffect(() => {
    const setInit = async () => {
      const uid = await AccountManager.getUid();
      hackleManager.setUserId(uid);
    };

    setTimeout(
      () => {
        setInit();
      },
      env.TEST_MODE === "true" ? 0 : 800,
    );
  }, []);

  return (
    <Container>
      <DialogPanel />
      <BrowserRouter>
        <Routes>
          <Route path="/android" element={<NoticeListPage />} />
          <Route path="/android/:id" element={<NoticeDetailPage />} />
          <Route path="/ios" element={<NoticeListPage />} />
          <Route path="/ios/:id" element={<NoticeDetailPage />} />
          <Route path="/evpost" element={<EVPostPage />} />
          <Route path="/hyundaioilbank" element={<HyundaiOilBankPage />} />
          <Route path="/hyundaioilbank/attend" element={<AttendPage />} />
          <Route path="/tracking" element={<TrackingPage />} />
          <Route path="/" element={<HashHandlerPage />} />
        </Routes>
      </BrowserRouter>
      <ToastMessage />
      <GlobalStyle />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const HashHandlerPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash.indexOf("#") >= 0) {
      navigate(location.hash.replace("#", ""), { replace: true });
    }
  }, [location]);

  return <div></div>;
};

export default App;
