export default {
  white: "#ffffff",
  black: "#000000",
  gray50: "#f8f9fa",
  gray100: "#f1f3f5",
  gray200: "#e9ecef",
  gray300: "#dee2e6",
  gray400: "#ced4da",
  gray500: "#adb5bd",
  gray600: "#868e96",
  gray700: "#495057",
  gray800: "#343a40",
  gray900: "#212529",
  blue50: "#ecf7ff",
  blue100: "#d4e6ff",
  blue200: "#b0d0fd",
  blue300: "#7da4fe",
  blue400: "#5783fc",
  blue500: "#4677ff",
  blue600: "#3563e4",
  blue700: "#2957d6",
  blue800: "#1b47c2",
  blue900: "#1540b8",
  red50: "#ffebee",
  red100: "#ffccd1",
  red200: "#f49ca0",
  red300: "#ff6b6b",
  red400: "#f8535a",
  red500: "#ff4141",
  red60: "#ef373f",
  red700: "#dd2c39",
  red800: "#d02532",
  red900: "#c11625",
  coolblue50: "#e2f1ff",
  coolblue100: "#c2ddf5",
  coolblue200: "#9ec9ee",
  coolblue300: "#7eb3e5",
  coolblue400: "#69a3e0",
  coolblue500: "#5b95da",
  coolblue600: "#5487cd",
  coolblue700: "#4b76ba",
  coolblue800: "#4465a8",
  coolblue900: "#374887",
  yellow50: "#fefae5",
  yellow100: "#fef1bb",
  yellow200: "#fee891",
  yellow300: "#ffe066",
  yellow400: "#ffd74a",
  yellow500: "#ffd03a",
  yellow600: "#ffc233",
  yellow700: "#ffb02d",
  yellow800: "#ffa028",
  yellow900: "#ff831e",
  green50: "#e5f6e7",
  green100: "#c1e8c4",
  green200: "#97d99e",
  green300: "#69cb76",
  green400: "#40c057",
  green500: "#00b437",
  green600: "#00a52d",
  green700: "#009321",
  green800: "#008214",
  green900: "#006200",
  orange50: "#fff3e4",
  orange100: "#ffe0bb",
  orange200: "#ffcd90",
  orange300: "#ffb867",
  orange400: "#ffa94d",
  orange500: "#fd9b40",
  orange600: "#f8903d",
  orange700: "#f18239",
  orange800: "#f77029",
  orange900: "#f05824",
};
