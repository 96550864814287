import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import ic_noti_new from "assets/icon/ic_noti_new.png";

type Props = {
  id: number | string;
  title: string;
  date: string;
  isNew: boolean;
  isBold?: boolean;
  onClick?: () => void;
};

function ListItem(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (props.onClick) {
      props.onClick();
    } else {
      const { id } = props;
      navigate(`${location.pathname}/${id}`);
    }
  };

  return (
    <Container onClick={onClickHandler}>
      <Title isBold={props.isBold}>{props.title}</Title>
      <SubContainer>
        {props.isNew ? <Image src={ic_noti_new} /> : null}
        <Date>{props.date}</Date>
      </SubContainer>
    </Container>
  );
}

const Container = styled.div`
  width: calc(100% - 40px);
  border-bottom: 1px solid #eeeeee;
  padding: 20px 0px;
`;

const SubContainer = styled.div`
  display: flex;
`;

const Title: React.ComponentType<any> = styled.div`
  font-size: 16px;
  color: ${(props: any) => (props.isBold ? "#5783FB" : "#3e4046")};
  line-height: 1.2;
  margin-bottom: 3px;
`;

const Image = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

const Date = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #8e8e93;
`;

export default ListItem;
