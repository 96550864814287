class AppCommunicator {
  private appVersion = "";
  private platform = "";
  private uid = "";

  public async getUid() {
    try {
      const win = window as any;

      if (win.oilnow) {
        this.uid = await win.oilnow.getUID();
      } else {
        const uid = localStorage.getItem("uid");
        this.uid = uid ? uid : "";
      }

      return this.uid;
    } catch {
      return "";
    }
  }

  public getAppVersion() {
    try {
      const win = window as any;

      if (win.oilnow) {
        this.appVersion = win.oilnow.getVersion();
      } else {
        const appVersion = localStorage.getItem("appVersion");
        this.appVersion = appVersion ? appVersion : "";
      }

      return this.appVersion;
    } catch {
      return "";
    }
  }

  public getPlatform() {
    try {
      const win = window as any;

      if (win.oilnow) {
        this.platform = win.oilnow.getPlatform();
      } else {
        const platform = localStorage.getItem("platform");
        this.platform = platform ? platform : "";
      }

      return this.platform;
    } catch {
      return "";
    }
  }

  public async logGaEvent(event: string, data?: any) {
    const win = window as any;

    if (win.oilnow) {
      await win.oilnow.logGaEvent(event);
    }

    if (win.webkit && win.webkit.messageHandlers.logGaEvent) {
      const message = {
        event,
        ...data,
      };
      win.webkit.messageHandlers.logGaEvent.postMessage(message);
    }
  }

  public goBack() {
    const win = window as any;

    if (win.oilnow) {
      win.oilnow.close();
      return true;
    } else if (win.webkit && win.webkit.messageHandlers.close) {
      win.webkit.messageHandlers.close.postMessage("");
      return true;
    }

    return false;
  }
}

export default new AppCommunicator();
