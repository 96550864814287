import { createContext } from "react";
import { makeAutoObservable } from "mobx";

interface ShowDialogProps {
  text: string;
  textAlign?: string;
  yesText: string;
  noText: string;
  title?: string;
  clickYesHandler: () => void;
  clickNoHandler?: (() => void) | undefined;
}

export class YesNoDialogStore {
  isOpen = false;
  title: string | undefined = undefined;
  text = "";
  textAlign = "";
  yesText = "";
  noText = "";

  constructor() {
    makeAutoObservable(this);
  }

  private clickYesHandler = () => {};
  private clickNoHandler = (() => {}) as (() => void) | undefined;

  showDialog = (props: ShowDialogProps) => {
    const {
      text,
      textAlign,
      yesText,
      noText,
      title,
      clickYesHandler,
      clickNoHandler,
    } = props;

    this.title = title;
    this.text = text;
    this.textAlign = textAlign ? textAlign : "left";
    this.yesText = yesText;
    this.noText = noText;
    this.isOpen = true;

    this.clickYesHandler = clickYesHandler;

    if (this.clickNoHandler !== undefined) {
      this.clickNoHandler = clickNoHandler;
    }
  };

  onClickYes = () => {
    this.clickYesHandler();
    this.isOpen = false;
  };

  onClickNo = () => {
    if (this.clickNoHandler !== undefined) {
      this.clickNoHandler();
    }
    this.isOpen = false;
  };
}

export default createContext(new YesNoDialogStore());
