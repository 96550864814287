import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import axios from "axios";

import AppCommunicator from "common/_helpers/app_communicator";
import AccountManager from "common/_helpers/account_manager";
import hackleManager from "common/_helpers/hackle_manager";
import { env } from "common/_helpers/config";

import OkDialog from "./_components/ok_dialog";

import color from "assets/color";
import checkbox_off from "./_assets/checkbox_off.svg";
import checkbox_on from "./_assets/checkbox_on.svg";

const nameRegex = /^[가-힣]{2,5}$/g;
const phoneRegex = /^\d{3}\d{3,4}\d{4}$/g;

type CustomInputProps = {
  label: string;
  text: string;
  className?: string;
  warning?: boolean;
  maxLength?: number;
  onChange: (text: string) => void;
};

const CustomInput = (props: CustomInputProps) => {
  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };

  return (
    <InputBox warning={props.warning} className={props.className}>
      <Label>{props.label}</Label>
      <Input
        value={props.text}
        maxLength={props.maxLength}
        onChange={handleChangeText}
      />
    </InputBox>
  );
};

const AttendPage = () => {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [unmatchedName, setUnmatchedName] = useState(false);
  const [unmatchedPhone, setUnmatchedPhone] = useState(false);
  const [isOkDialogOpen, setIsOkDialogOpen] = useState(false);

  const clickCheckboxHandler = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (name.length !== 0) {
      setUnmatchedName(!name.match(nameRegex));
    }
  }, [name]);

  useEffect(() => {
    if (phone.length !== 0) {
      setUnmatchedPhone(!phone.match(phoneRegex));
    }
  }, [phone]);

  const handleClickButton = async () => {
    if (
      isChecked &&
      name.length > 0 &&
      phone.length > 0 &&
      !unmatchedPhone &&
      !unmatchedName
    ) {
      const token = await AccountManager.getAccessToken();

      try {
        await axios.post(
          `${env.ACCOUNT_API_URL}/partnership/event/oilbank-01/attendee`,
          {
            name,
            phone,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        AppCommunicator.logGaEvent("cta_230501_hyundaioilbank_submit");
        hackleManager.track("cta_230501_hyundaioilbank_submit");
      } catch {
        return;
      }

      setIsOkDialogOpen(true);
    }
  };

  const handleClickDialogOk = () => {
    navigate(-1);
  };

  return (
    <>
      <OkDialog
        text={"이벤트 참여를 완료했습니다."}
        isOpen={isOkDialogOpen}
        onClickOk={handleClickDialogOk}
      />
      <Container>
        <Title>
          {`주유 쿠폰을 받을 전화번호를 입력해 주시면
              추첨을 통해 쿠폰을 발송해 드려요!`}
        </Title>
        <NameInput
          text={name}
          onChange={setName}
          label={"이름"}
          maxLength={8}
        />
        {unmatchedName && (
          <WarningText>{"정확한 이름을 입력해 주세요"}</WarningText>
        )}
        <PhoneInput
          text={phone}
          onChange={setPhone}
          label={"휴대전화 번호"}
          warning={unmatchedPhone}
          maxLength={11}
        />
        {unmatchedPhone && (
          <WarningText>
            {"띄어쓰기나 특수문자 없이 숫자만 입력해 주세요"}
          </WarningText>
        )}
        <CheckBox onClick={clickCheckboxHandler}>
          <CheckIcon src={isChecked ? checkbox_on : checkbox_off} alt={""} />
          {"동의합니다."}
        </CheckBox>
        <InfoText>
          {`운전자님께 주유 쿠폰을 발송하기 위해 [이름, 전화번호] 제공 동의가 필요합니다.

            제3자 개인 정보 제공 동의
            - 업체 : HD현대오일뱅크(주)
            - 제공 목적 : 이벤트 당첨자 5천원 주유 쿠폰 발송
            - 제공 항목 : 이름, 전화번호
            - 보유 기간 : 혜택 지급 후 30일 이내 파기

            * 위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 프로모션 참여가 제한됩니다.`}
        </InfoText>

        <AttendButton
          onClick={handleClickButton}
          enabled={
            isChecked &&
            name.length > 0 &&
            phone.length > 0 &&
            !unmatchedName &&
            !unmatchedPhone
          }
        >
          {"이벤트 참여하기"}
        </AttendButton>
      </Container>
    </>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 1.6rem 2.8rem 3.4rem 2.8rem;
`;

const Title = styled.p`
  width: 100%;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.34rem;
  color: ${color.gray700};
  white-space: pre-line;
`;

const InputBox = styled.div<{ warning?: boolean }>`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.warning ? color.red500 : color.gray400)};
  padding-bottom: 1rem;
`;

const Label = styled.p`
  color: ${color.gray700};
  margin-left: 0.4rem;
`;

const Input = styled.input`
  width: 100%;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: ${color.gray700};
  border: none;
  margin-top: 0.4rem;
  padding-left: 0.4rem;
`;

const WarningText = styled.p`
  width: 100%;
  margin-top: 0.4rem;
  margin-bottom: 2.7rem;
  padding-left: 0.4rem;
  line-height: 2rem;
  color: ${color.red500};
`;

const NameInput = styled(CustomInput)`
  margin-top: 2rem;
`;

const PhoneInput = styled(CustomInput)`
  margin-top: 3.2rem;
`;

const InfoText = styled.p`
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${color.gray600};
  white-space: pre-line;
  margin-bottom: 15rem;
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2.8rem;
  margin-bottom: 1.2rem;
  padding-left: 0.2rem;
  line-height: 2rem;
`;

const CheckIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
  margin-top: 0.2rem;
`;

const AttendButton = styled.button<{ enabled: boolean }>`
  position: fixed;
  bottom: 3.4rem;
  width: 24rem;
  height: 4.5rem;
  border-radius: 2rem;
  background: ${(props) => (props.enabled ? "#0D5EAF" : color.gray500)};
  font-size: 2rem;
  line-height: 2.6rem;
  color: ${color.white};
  margin-top: 3.3rem;
`;

export default AttendPage;
