import React from "react";
import styled from "styled-components";
import color from "assets/color";
import VisibleProps from "common/_types/visible_props";
import zIndex from "common/_types/z_index";

type Props = {
  isOpen: boolean;
  title?: string;
  text: string;
  textAlign: string;
  okText: string;
  onClickOk: () => void;
};

function OkDialog(props: Props) {
  return (
    <>
      <Dimmer isVisible={props.isOpen} />
      <Container isVisible={props.isOpen}>
        <Dialog>
          <TextBox>
            {props.title && props.title.length !== 0 && (
              <Title textAlign={props.textAlign}>{props.title}</Title>
            )}
            <Text textAlign={props.textAlign}>{props.text}</Text>
          </TextBox>
          <ButtonContainer>
            <OkButton onClick={props.onClickOk}>{props.okText}</OkButton>
          </ButtonContainer>
        </Dialog>
      </Container>
    </>
  );
}

const Container = styled.div<VisibleProps>`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props: VisibleProps) =>
    props.isVisible ? "translateY(0%)" : "translateY(100%)"};
  transition: transform 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out;
  z-index: ${zIndex.layer5};
`;

const Dimmer = styled.div<VisibleProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: ${(props: VisibleProps) => (props.isVisible ? "block" : "none")};
  background-color: rgba(0, 0, 0, 0.6);
  z-index: ${zIndex.layer5};
`;

const Dialog = styled.div`
  width: 300px;
  height: auto;
  background: white;
  border-radius: 5px;
  top: 50%;
`;

const TextBox = styled.div`
  width: 100%;
  padding: 20px 20px 16px 20px;
  border-bottom: solid 0.5px #eeeeee;
`;

const Title = styled.h1<{ textAlign: string }>`
  margin-bottom: 16px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: ${color.gray800};
`;

const Text = styled.div<{ textAlign: string }>`
  display: flex;
  width: 100%;
  line-height: 24px;
  align-items: center;
  white-space: pre-line;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${color.gray800};
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 52px;
`;

const OkButton = styled.button`
  background: white;
  width: 100%;
  height: 100%;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  color: ${color.blue400};
`;

export default OkDialog;
