import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga";
import { HackleProvider } from "@hackler/react-sdk";

import App from "./shared/App";
import * as serviceWorker from "./serviceWorker";
import hackleManager from "common/_helpers/hackle_manager";
import ReactQuerySetUp from "common/_libs/react_query/react_query_set_up";

ReactGA.initialize("UA-125420657-2", {
  debug: false,
});
ReactGA.pageview(window.location.pathname + window.location.search);

createRoot(document.getElementById("root") as HTMLElement).render(
  <HackleProvider hackleClient={hackleManager.hackleClient}>
    <ReactQuerySetUp>
      <App />
    </ReactQuerySetUp>
  </HackleProvider>,
);
serviceWorker.unregister();
