import { createContext } from "react";
import { makeAutoObservable } from "mobx";

interface ShowDialogProps {
  text: string;
  textAlign?: string;
  okText?: string;
  clickOkHandler?: (() => void) | undefined;
  title?: string;
}

export class OkDialogStore {
  isOpen = false;
  title = "";
  text = "";
  okText = "";
  textAlign = "";

  constructor() {
    makeAutoObservable(this);
  }

  private clickOkHandler = (() => {}) as (() => void) | undefined;

  showDialog = ({
    text,
    textAlign,
    okText,
    clickOkHandler,
    title,
  }: ShowDialogProps) => {
    this.title = title ? title : "";
    this.text = text;
    this.textAlign = textAlign ? textAlign : "left";
    this.isOpen = true;
    this.okText = okText ? okText : "확인";
    this.clickOkHandler = clickOkHandler;
  };

  onClickOk = () => {
    if (this.clickOkHandler) {
      this.clickOkHandler();
    }
    this.isOpen = false;
  };
}

export default createContext(new OkDialogStore());
