import React from "react";
import styled from "styled-components";

import zIndex from "common/_types/z_index";
import color from "assets/color";
import ic_check from "../_assets/ic_check.svg";

type OkDialogProps = {
  isOpen: boolean;
  text: string;
  onClickOk: () => void;
};

const OkDialog = (props: OkDialogProps) => {
  const handleClickDailogOk = () => {
    props.onClickOk();
  };

  return (
    <>
      {props.isOpen && <Dimmer />}
      <DialogContainer visible={props.isOpen}>
        <Dialog>
          <DialogCheckIcon src={ic_check} alt={""} />
          <DialogText>{props.text}</DialogText>
          <ButtonContainer>
            <OkButton onClick={handleClickDailogOk}>{"확인"}</OkButton>
          </ButtonContainer>
        </Dialog>
      </DialogContainer>
    </>
  );
};

const Dimmer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 5;
`;

const DialogContainer = styled.div<{ visible: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) =>
    props.visible ? "translateY(0%)" : "translateY(100%)"};
  transition: transform 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out;
  z-index: ${zIndex.layer5};
`;

const Dialog = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 28rem;
  height: auto;
  background: white;
  border-radius: 0.6rem;
  top: 50%;
`;

const DialogCheckIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  margin-top: 2rem;
  margin-bottom: 1.6rem;
`;

const DialogText = styled.div`
  white-space: pre-line;
  font-weight: 700;
  font-size: 1.8rem;
  color: ${color.gray800};
  margin-bottom: 1.6rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 5.2rem;
  border-top: solid 0.5px #eeeeee;
`;

const OkButton = styled.button`
  background: white;
  width: 100%;
  height: 100%;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  color: ${color.blue400};
`;

export default OkDialog;
