export const compareVersion = (source: string, target: string) => {
  const sourceArr = source.split(".");
  const targetArr = target.split(".");

  const length = Math.max(sourceArr.length, targetArr.length);

  for (let i = 0; i < length; i += 1) {
    const a = sourceArr[i] ? parseInt(sourceArr[i], 10) : 0;
    const b = targetArr[i] ? parseInt(targetArr[i], 10) : 0;

    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    }
  }

  return 0;
};
