import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import { useLocation } from "react-router-dom";
import throttle from "lodash/throttle";

import PlatformType from "domain/enum/platform_type";
import GlobalStore from "domain/store/global_store";

import NoticeItem from "common/_components/list_item";
import useScrollMove from "common/_hooks/use_scroll_move";
import useNotices from "./_hooks/use_notices";
import NavigationBar from "common/_components/navigation_bar";
import withNavigationBar from "../_helpers/with_navigation_bar";

function NoticeListPage() {
  const location = useLocation();

  const { notices, fetchNextNotices } = useNotices({
    platform: location.pathname.includes("android")
      ? PlatformType.ANDROID
      : PlatformType.IOS,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollMove } = useScrollMove({
    dom: containerRef,
    timeout: 10,
  });

  const globalStore = useContext(GlobalStore);

  useEffect(() => {
    // 백버튼 클릭을 알리기 위해 store 사용.
    if (globalStore.isAlreadySetBackButtonEvent === false) {
      window.addEventListener("popstate", () => {
        globalStore.isOnBackButtonEvent = true;
      });

      globalStore.isAlreadySetBackButtonEvent = true;
    }

    // 스크롤 복구
    scrollMove();

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (globalStore.isOnBackButtonEvent) {
      globalStore.isOnBackButtonEvent = false;
    }
  }, [globalStore.isOnBackButtonEvent]);

  const handleScroll = throttle(() => {
    const element = containerRef.current;

    if (!element) return;

    const scrollPosition = element.scrollTop + element.clientHeight;
    const scrollHeight = element.scrollHeight;

    if (scrollPosition >= scrollHeight * 0.9) {
      fetchNextNotices();
    }
  }, 200);

  return (
    <Container ref={containerRef}>
      {notices.map((notice) => (
        <NoticeItem
          key={notice.id}
          id={notice.id}
          title={notice.title}
          date={moment(notice.date).format("YYYY년 M월 D일")}
          isNew={false}
        />
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow-y: scroll;
`;

export default withNavigationBar(NoticeListPage, { lastPage: true });
