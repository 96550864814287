import { createContext } from "react";
import { makeAutoObservable } from "mobx";

export class GlobalStore {
  public isOnBackButtonEvent = false;
  public isAlreadySetBackButtonEvent = false;

  constructor() {
    makeAutoObservable(this);
  }
}

export default createContext(new GlobalStore());
