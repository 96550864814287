export enum NoticeType {
  NOTICE = "NOTICE",
  CONTENT = "CONTENT",
}

export default interface Notice {
  id: number;
  type: NoticeType;
  title: string;
  description: string;
  platform: number;
  isNotified: boolean;
  html: string;
  link: string;
  timestamp: Date;
  date: Date;
}
