import HackleDevTools from "@hackler/javascript-devtools";
import { PropertyOperationsBuilder, createInstance } from "@hackler/react-sdk";
import { HackleReactSDKClient } from "@hackler/react-sdk/lib/client";
import { env } from "./config";

let isLocalhost = false;

// window 객체가 존재하는지 확인하여 클라이언트 사이드인지 판단
if (typeof window !== "undefined") {
  isLocalhost = window.location.hostname === "localhost";
}

const config = {
  debug: env.ENVIRONMENT === "development",
  devTool: HackleDevTools,
  autoOpenDevTool: isLocalhost,
};

class HackleManager {
  hackleClient: HackleReactSDKClient;

  constructor() {
    this.hackleClient = createInstance(`${env.HACKLE_KEY}`, config);
  }

  setUserId = (userId: string) => {
    this.hackleClient.setUserId(userId);
  };

  setUserProperty = (
    props:
      | { key: string; value: string | number | boolean }[]
      | { name: string; value: number }
  ) => {
    let formattedProps = Array.isArray(props)
      ? props
      : [{ key: props.name, value: props.value }];
    let operations = new PropertyOperationsBuilder();

    formattedProps.forEach((prop) => {
      operations = operations.set(prop.key, prop.value);
    });

    const builtOperations = operations.build();
    this.hackleClient.updateUserProperties(builtOperations);
  };

  resetUser = () => {
    this.hackleClient.resetUser();
  };

  track = (eventName: string, eventProperties?: any) => {
    const formattedEventName = eventName.replace(/ /g, "_");

    if (eventProperties) {
      const trackingData = {
        key: formattedEventName,
        properties: eventProperties,
      };
      this.hackleClient.track(trackingData);
    } else {
      this.hackleClient.track(formattedEventName);
    }
  };
}

const hackleManager = new HackleManager();

export default hackleManager;
