import { useAtomValue } from "jotai";
import React from "react";
import styled from "styled-components";

import { isToastOpenAtom, toastMessageAtom } from "./_atom";

import color from "assets/color";
import zIndex from "common/_types/z_index";

export const ToastMessage = () => {
  const toastMessage = useAtomValue(toastMessageAtom);
  const isToastOpen = useAtomValue(isToastOpenAtom);

  if (!isToastOpen) return null;

  return <ToastBox>{toastMessage}</ToastBox>;
};

const ToastBox = styled.div`
  border-radius: 0.6rem;
  background: rgba(11, 18, 25, 0.7);
  backdrop-filter: blur(0.25rem);
  min-width: 34.3rem;
  padding: 2rem;
  color: ${color.white};
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
  top: 5.6rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${zIndex.layer5};
`;
